<template>
  <div>
    <AssistenciaSocial />
  </div>
</template>

<script>
import AssistenciaSocial from './components/AssistenciaSocial.vue';

export default {
  name: 'App',
  components: {
    AssistenciaSocial,
  },
}
</script>