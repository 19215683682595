<template>
  <div class="bg-gray-100 font-poppins">
    <!-- Header section -->
    <header class="bg-white shadow-lg flex items-center justify-center">
      <img class="w-72 md:w-full md:max-w-xs mx-auto" src="../assets/img/SMASDHDC_Secretaria_Logo-Vazado_Horizontal_.png"
        alt="Logo">
    </header>

    <!-- Main content section -->
    <main class="container mx-auto px-4 py-8 text-center">

      <div class="container mx-auto py-8">
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">

          <div
            class="bg-gray-200 flex items-center justify-center p-2 mb-2 -mt-6 md:mb-0 rounded-md visible md:hidden shadow-lg md:shadow-2xl">
            <img class="w-full mb-2" src="../assets/img/preview_vetor.png"
              alt="Conferência Municipal de Assistência Social">
          </div>

          <div class="flex justify-center items-center">
            <div class="bg-gray-200 p-8 2xl:p-14 rounded-md">
              <h1 class="text-xl md:text-2xl font-bold mb-4">Encontros Preparatórios com Usuários dos Serviços
                Socioassistenciais</h1>
              <p class="mb-5 text-lg">Dias: 26 e 27 de abril de 2023</p>
              <p class="mb-2 md:mb-8 -mt-5 text-lg">1º, 2º, 3º e 4º Distritos</p>
            </div>
          </div>

          <div class="bg-gray-200 flex items-center justify-center p-2 rounded-md invisible md:visible shadow-2xl">
            <img class="w-full mb-2" src="../assets/img/preview_vetor.png"
              alt="Conferência Municipal de Assistência Social">
          </div>

          <div class="bg-gray-200 p-8 flex justify-center items-center rounded-md -mt-64 md:mt-0">
            <div class="text-center">
              <h2 class="text-2xl font-bold mb-4">Assembleia Ampliada</h2>
              <p class="mb-5 text-lg">Dia: 25 de maio de 2023</p>
              <p class="mb-8 md:mb-5 -mt-5 text-lg">Local: Museu Ciência e Vida</p>
            </div>
          </div>

        </div>
      </div>

      <div class="flex justify-center items-center bg-blue-500 rounded-lg mb-10">
        <div class="text-white p-8">
          <h3 class="text-2xl font-bold mb-4">Tema:
            <span class="text-gray-300">“</span>Reconstrução do
            <span class="text-yellow-custom">SUAS: O SUAS</span> que temos e o
            <span class="text-yellow-custom">SUAS</span> que queremos<span class="text-gray-300">“</span>
          </h3>
          <p class="mb-8 text-xl">Dias: 28 e 29 de junho de 2023, no horário de 09 às 17 horas</p>
          <p class="mb-2 -mt-5 text-base">Local: Auditório da Universidade Estácio de Sá - Campus Duque de Caxias,
            <br>Rua:
            Major Correia de Melo Nº 86 - Jardim 25 de Agosto - Duque de Caxias/RJ.
          </p>
        </div>
      </div>


      <div class="grid grid-cols- md:grid-cols-2 gap-8 mb-8">
        <div class="bg-azul-claro border border-gray-300 p-4 rounded-lg">
          <h4 class="text-xl font-bold mb-2">Eixo 1 - Financiamento</h4>
          <p class="mb-2 text-lg md:text-base">Financiamento e orçamento de natureza obrigatória, como instrumento para
            uma gestão de
            compromisso e responsabilidades dos entes federativos para garantia dos direitos socioassistenciais
            contemplando as especificidades regionais do país.</p>
        </div>

        <div class="bg-verde-claro border border-gray-300 p-4 rounded-lg">
          <h4 class="text-xl font-bold mb-6">Eixo 2 - Controle Social</h4>
          <p class="mb-2 text-lg md:text-base">Qualificação e estruturação das instâncias de Controle Social com
            diretrizes democráticas e
            participativas.</p>
        </div>

        <div class="bg-laranja-claro border border-gray-300 p-4 rounded-lg">
          <h4 class="text-xl font-bold mb-2">Eixo 3 - Articulação entre segmentos</h4>
          <p class="mb-2 text-lg md:text-base">Como potencializar a participação social no SUAS?</p>
        </div>

        <div class="bg-roxo-claro border border-gray-300 p-4 rounded-lg">
          <h4 class="text-xl font-bold mb-2">Eixo 4 - Serviços, Programas e Projetos</h4>
          <p class="mb-2 text-lg md:text-base">Universalização do acesso e a integração das ofertas dos serviços e
            direitos no SUAS.</p>
        </div>

      </div>

      <div class="flex items-center justify-center h-full">
        <div class="bg-cinza-claro border border-gray-300 p-7 rounded-lg">
          <h4 class="text-xl md:text-lg font-bold mb-2">Eixo 5 - Benefício e Transferência de Renda</h4>
          <p class="mb-2 text-lg md:text-base">A importância dos benefícios socioassistenciais e o direito à garantia de
            renda como <br>proteção
            social na reconfiguração do SUAS.</p>
        </div>
      </div>

      <div class="flex flex-wrap justify-center items-center pt-10">
        <div class="w-full sm:w-1/2 lg:w-1/3 p-4">
          <div class="border border-gray-300 p-4 rounded-lg">
            <h4 class="text-xl md:text-lg font-bold mb-2">Resolução nº <br>024/CMAS/2023</h4>
            <a href="https://duquedecaxias.rj.gov.br/portal/arquivos/2023/abril/Boletim_7276_10_de_Abril_2023.pdf"
              target="_blank" class="inline-block bg-blue-custom text-white px-8 py-2 rounded hover:bg-blue-600">Clique
              aqui</a>
          </div>
        </div>

        <div class="w-full sm:w-1/2 lg:w-1/3 p-4">
          <div class="border border-gray-300 p-4 rounded-lg">
            <h4 class="text-xl md:text-lg font-bold mb-2">Resolução n°<br>031/CMAS/2023</h4>
            <a href="http://duquedecaxias.rj.gov.br/portal/arquivos/2023/maio/Boletim_7285_05_de_Maio_2023.pdf"
              target="_blank"
              class="inline-block bg-gray-500 text-white px-8 py-2 rounded hover:bg-blue-600">Clique aqui</a>
          </div>
        </div>

        <div class="w-full sm:w-1/2 lg:w-1/3 p-4">
          <div class="border border-gray-300 p-4 rounded-lg">
            <h4 class="text-xl md:text-lg font-bold mb-2">Errata da Resolução <br>nº 024/CMAS/2023</h4>
            <a href="https://duquedecaxias.rj.gov.br/portal/arquivos/2023/abril/Boletim_7278_14_de_Abril_2023.pdf"
              target="_blank"
              class="inline-block bg-orange-custom text-white px-8 py-2 rounded hover:bg-orange-600">Clique aqui</a>
          </div>
        </div>
      </div>

    </main>

    <p class="text-center font-bold text-2xl">REALIZAÇÃO: </p>

    <div class="flex justify-center items-center">
      <div class="w-full max-w-screen-lg flex flex-wrap justify-center md:justify-between px-4">
        <div class="w-28 p-4 pt-6">
          <img src="../assets/img/Logo_do_CMAS.png" alt="Conselho Municipal de Assistência Social de Duque de Caxias">
        </div>
        <div class="w-28 p-4">
          <img src="../assets/img/suas_sem_fundo_branco.png" alt="Sistema único de Assistência Social">
        </div>
        <div class="w-32 p-4">
          <img src="../assets/img/cnas_sem_fundo_branco.png" alt="Conselho Nacional de Assistência Social">
        </div>
        <div class="w-80 md:w-64 p-4 -mt-6 md:mt-6">
          <img src="../assets/img/SMASDHDC_Secretaria_Logo-Vazado_Horizontal_.png"
            alt="Conselho Nacional de Assistência Social">
        </div>
      </div>
    </div>

    <!-- Footer section -->
    <footer class="bg-white shadow">
      <div class="container mx-auto px-4 py-4 text-center">
        <p class="text-sm md:text-base text-gray-700">&copy; 2023 Assistência Social & Direitos Humanos</p>
      </div>
    </footer>
  </div>
</template>